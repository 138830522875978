<template>
  <button
    class="button aria-[invalid='true']:bg-error"
    :aria-disabled="state.loading || loading"
    :aria-label="state.loading || loading ? 'lädt...' : undefined"
    :aria-invalid="state.error"
    :class="{ '!bg-success': (success || state.success) && $slots.success, 'cursor-wait': state.loading || loading }"
    v-bind="state.loading || loading ? ($slots.loading ? {} : { 'aria-label': 'lädt...' }) : {}"
    @click="execPromise"
  >
    <transition name="fade" mode="out-in">
      <span v-if="state.error || error" class="flex items-center justify-center">
        <!-- <nuxt-icon name="error" class="inline-block text-xl [&>svg]:!mb-0 [&>svg]:stroke-[.25rem]" filled /> -->
        <span v-if="$slots.error">&nbsp;</span>
        <slot name="error">Ein Fehler ist aufgetreten</slot>
      </span>
      <span v-else-if="state.loading || loading" class="flex items-center justify-center">
        <nuxt-icon name="loading" class="inline-block text-xl [&>svg]:!mb-0 [&>svg]:stroke-[.25rem]" filled />
        <template v-if="$slots.loading">&nbsp;</template>
        <slot name="loading" />
      </span>
      <span v-else-if="(state.success || success) && $slots.success" class="flex items-center justify-center">
        <slot name="success" />
      </span>
      <span v-else class="flex w-full items-center justify-center"><slot /></span>
    </transition>
  </button>
</template>

<script setup lang="ts">
defineSlots<{
  default(): any
  loading(): any
  success(): any
  error(): any
}>()

const props = withDefaults(defineProps<{ promise?: null | (() => unknown); loading?: boolean; error?: boolean; success?: boolean; }>(), {
  loading: false,
  error: false,
  success: false,
  promise: null,
})
const emit = defineEmits<{
  (event: "loading"): void
  (event: "success", res: unknown): void
  (event: "error", err: Error): void
}>()

const state = ref({
  loading: false,
  error: false,
  success: false,
  reset: function () {
    this.loading = false
    this.error = false
    this.success = false
  },
})

async function execPromise() {
  if (props.promise) {
    if (!state.value.loading) {
      state.value.reset()

      emit("loading")
      const loadTimeout = setTimeout(() => (state.value.loading = true), 200)

      try {
        const res: unknown = await props.promise!()

        state.value.success = true
        state.value.loading = false
        emit("success", res)
      } catch (err) {
        state.value.loading = false
        state.value.error = true
        emit("error", err as Error)

        setTimeout(() => {
          state.value.error = false
          state.value.reset()
        }, 3000)
      } finally {
        clearTimeout(loadTimeout)
      }
    }
  }
}
</script>
